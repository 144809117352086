import { Fragment } from "react";
import i18next from "i18next";

import Loader from "../../Loader/Loader";

import classes from "./TrialModalContent.module.css";

function TrialModalContent(props) {
  // console.log('cancelModalContent-props', props);
  const {
    setShowTrialModal,
    createTrialSubscription,
    selectedTrialId,
    isLoading,
  } = props;

  return (
    <Fragment>
      <div>
        <div className={classes.subscriptionModalTitle}>
          {i18next.t("subText29", "Please Confirm Your Action")}
        </div>
        <button
          className={classes.subscriptionCancelButton}
          disabled={isLoading}
          onClick={() => {
            setShowTrialModal(false);
          }}
        >
          {i18next.t("subText30", "Close")}
        </button>
        <button
          className={classes.subscriptionButton}
          disabled={isLoading}
          onClick={() => {
            createTrialSubscription(selectedTrialId);
          }}
        >
          {i18next.t("subText33", "Start Trial")}
        </button>

        {isLoading && (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default TrialModalContent;
