import { Fragment, useRef, useState, useEffect } from "react";
// import { useRouter } from 'next/router';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Loader from "../../Loader/Loader";
import Paginator from "../../Paginator/Paginator";
import UserRoomFilter from "./UserRoomFilter";
import UserRoomListItem from "./UserRoomListItem";

import { useStore } from "../../../hooks-store/store";

import { BACKEND_URL } from "../../../App";

import { marks } from "../../../images/marks";
import classes from './UserRoomList.module.css';


const perPagePost = 20;

function UserRoomList(props) {
  // console.log(props);
  // console.log(i18next);

  const userLoginEmail = localStorage.getItem('email') ? localStorage.getItem('email') : '';


  const [store, dispatch] = useStore();
  const userRoomList = store.userRoomStore.userRoomList;
  const filterStart = store.userRoomStore.filterStart;
  const filterEnd = store.userRoomStore.filterEnd;
  const filteredRoomList = store.userRoomStore.filteredRoomList;

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userLoginEmail) {
      getUserRooms(BACKEND_URL, localStorage.getItem('token'));
    }
  },[userLoginEmail]);


  const getUserRooms = async (url, token) => {
    try {
      setIsLoading(true);

      const result = await fetch(url + "/user-room/user-rooms", {
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + token,
          "Content-Type": "application/json",
        }
        // body: JSON.stringify(meetupDataObj),
      });

      const resData = await result.json();
      console.log(resData, result);

      if (!result.ok) {
        throw new Error('error-occured');
      }

      dispatch('SET_USERROOM_LIST', resData.data);

      setIsLoading(false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };


  const pageStart = (currentPage -1) * perPagePost;
  const pageEnd = currentPage * perPagePost;

  let displayList = userRoomList;

  if (filterStart === 0 && filterEnd === 0) {
    displayList = userRoomList;
  }

  if (filterStart !== 0 && filterEnd !== 0 && filteredRoomList.length >= 0) {
    displayList = filteredRoomList;
  }


  let userRoomListBody = <div>no-room</div>;

  if (userRoomList.length > 0) {

    userRoomListBody = (
      <ul>
        {displayList.slice(pageStart, pageEnd).map((room, index) => {
          return (
            <li key={index}>
              <UserRoomListItem 
                room={room}
              />
            </li>
          );
        })}
      </ul>
    );
  }


  return (
  <Fragment>
      <div className={classes.userRoomListTitle}>
        {i18next.t('userRoom.text01', 'Your Rooms')}
      </div>
      <div className={classes.userRoomListDescription}>
        {i18next.t('userRoom.text02', 'Please make sure details about your room in Email. The Email was sent when the room is created or updated.')}
      </div>
      {isLoading && (
        <div className="centerLoader">
          <Loader />
        </div>
      )}

      {userRoomList.length > 0 && (
        <section>
          <div className={classes.userRoomListShowFilter}>
            <span className={classes.userRoomListShowFilterButton}
              onClick={() => { setShowFilter(!showFilter); }}
            >
              {/* Specify Period {marks.triangleDown} */}
              {i18next.t('userRoom.text03', 'Specify Period')} {marks.triangleDown}
            </span>
          </div>
          {showFilter && (
            <UserRoomFilter 
              setCurrentPage={setCurrentPage}
            />
          )}
        </section>
      )}


      {!isLoading && (
        <section>
          <span>{userRoomListBody}</span>
          <Paginator 
            onPrevious={() => { setCurrentPage(currentPage - 1); }}
            onNext={() => { setCurrentPage(currentPage + 1); }}
            lastPage={Math.ceil(displayList.length / perPagePost)}
            currentPage={currentPage}
            getStoreCurrentPage={() => {}}
            posts={displayList}
            noScroll={'no-scroll'}
          />
        </section>
      )}
  </Fragment>);
}

export default UserRoomList;
