import { Fragment, useState, useEffect } from "react";
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Loader from "./Loader/Loader";
import { useStore } from "../hooks-store/store";
import { getRoomConfig } from "../utils/new-meetup";
import { BACKEND_URL } from "../App";

import classes from './getConfig.module.css';

function GetConfig() {
  // const { t } = useTranslation("common");

  const [store, dispatch] = useStore();
  // console.log(store);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //// get room config from api initially or when config is older
    if (!store.roomConfig || store.roomConfig.lastGetDate < (Date.now() - 1000*60*5)) {
      setIsLoading(true);

      getRoomConfig(BACKEND_URL)
        .then((res) => {
          console.log('get config result', res);
          dispatch("SET_ROOMCONFIG", res.data);
          // setRoomConfig(res.data);
  
          setIsLoading(false);
        })
        .catch((err) => {
          console.log('get config error', err);
  
          if (err.message === "get-api-failed") {
            setError(
              i18next.t("roomForm.text19",'Service is not available now')
            );
          } else {
            setError(err.message);
          }
  
          setIsLoading(false);
        });
    }
  }, []);

  return <Fragment>
    {isLoading && <div className={classes.getConfigLoader}><Loader /></div>}
    {error && <h1>{error}</h1>}
    </Fragment>;
}

export default GetConfig;
