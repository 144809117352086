import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./index.css";
import App from "./App";
import { SUBSCRIPTION_BACKEND_URL } from "./App";
import reportWebVitals from "./reportWebVitals";

// import { fetchPrices } from "./utils/stripe";

import configureCounterStore from "./hooks-store/counter-store";
import configureUiStore from "./hooks-store/ui-store";
import configureStripeDataStore from "./hooks-store/stripe-data-store";
import configureUserRoomStore from './hooks-store/user-room-store';

configureCounterStore();
configureUiStore();
configureStripeDataStore();
configureUserRoomStore();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);


// fetchPrices()
//   .then((data) => {
//     // console.log(data);
//     const stripePromise = loadStripe(data.publishableKey);

//     ReactDOM.render(
//       <BrowserRouter>
//         <Elements stripe={stripePromise}>
//           <App />
//         </Elements>
//       </BrowserRouter>,
//       document.getElementById("root")
//     );
//   })
//   .catch((err) => {
//     console.log(err);

//     ReactDOM.render(
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>,
//       document.getElementById("root")
//     );
//   });


//// load stripe publishableKey
fetch(SUBSCRIPTION_BACKEND_URL + '/price/config')
  .then(r => {
    return r.json()
  })
  .then(resData => {
    // console.log('stripe-config resData', resData);
    const stripePromise = loadStripe(resData.publishableKey);

    ReactDOM.render(
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </BrowserRouter>,
      document.getElementById("root")
    );

  })
  .catch(err => {
    // console.log('stripe-config err', err)
  });
  
// fetchPrices()
//   // .then((response) => response.json())
//   .then((data) => {
//     // console.log(data)
//     const stripePromise = loadStripe(data.publishableKey);

//     ReactDOM.render(
//       <BrowserRouter>
//         <Elements stripe={stripePromise}>
//           <App />
//         </Elements>
//       </BrowserRouter>,
//       document.getElementById('root')
//     );
//   })
//   .catch((error) => {
//     console.error('Error:', error);
//   });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
