import { Fragment, useRef, useState, useEffect } from "react";
// import { useRouter } from 'next/router';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';
import Calendar from 'react-calendar';
import dateAndTime from 'date-and-time';
import { differenceInCalendarDays } from 'date-fns';

import Backdrop from '../../backdrop/Backdrop';
import CreateRoomModal from "../../modal/CreateRoomModal";
import ModalSimple from "../../modal/ModalSimple";
import NewMeetupForm from "../../../pages/new-meetup/NewMeetupForm";
import RoomDayModalContent from "./RoomDayModalContent";

import { useStore } from "../../../hooks-store/store";

// import './Sample.less';
// import 'react-calendar/dist/Calendar.css';
import './Calendar.css';

// import classes from './UserRoomCalender.module.css';

function UserRoomCalendar(props) {

  const [store, dispatch] = useStore();
  const userRoomList = store.userRoomStore.userRoomList;
  const { endFutureLimit } = store.roomConfig;

  const [value, setValue] = useState(new Date());

  const [selectedDayRoomList, setSelectedDayRoomList] = useState([]);
  const [showRoomDayModal, setShowRoomDayModal] = useState(false);
  const [showCreateRoomModal, setShowCreateRoomModal] = useState(false);

  const lastTime = Date.now() + endFutureLimit - 1000*60*60*24;

  let calLocale = navigator.language;

  const userSelectedLng = localStorage.getItem('userSelectLng');

  if (userSelectedLng) {
    calLocale = userSelectedLng;
  }

  function onChange(nextValue) {
    console.log('nextValue', nextValue);
    setValue(nextValue);
  }

  function onClickDayHandler(value, event) {
    console.log(value, event);
    // console.log(checkRoomDay(value, userRoomList));
    const dayCheck = checkRoomDay(value, userRoomList);
    console.log(dayCheck);

    if (dayCheck.isRoomDay) {
      // alert('room-day');
      setSelectedDayRoomList(dayCheck.rooms);
      setShowRoomDayModal(true);
    } else {
      setSelectedDayRoomList([]);
      setShowRoomDayModal(false);
    }


    const dayValue = new Date(value).getTime();

    if (dayValue < lastTime) {
      setShowRoomDayModal(true);
    }
    // console.log(new Date(value));
  }

  function tileContentFn({date, view}) {
    // console.log(date);

    const isUserRoomExist = checkRoomDay(date, userRoomList);
    // console.log(date, isUserRoomExist);

    if (isUserRoomExist.isRoomDay) {
      return <strong style={{fontSize:"1.5rem"}}> *</strong>
    }
    // if (view === 'month' && date.getDay() === 0) {
    //   return <span style={{color: "green"}}>**!!</span>
    // } 
    else {
      return null;
    }
    // ({ date, view }) => view === 'month' && date.getDay() === 0 ? <p>It's Sunday!</p> : null
  }

  // const disabledDates = [tomorrow, in3Days, in5Days];

  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  // function tileDisabled({ date, view }) {
  //   // Disable tiles in month view only
  //   if (view === 'month') {
  //     // Check if a date React-Calendar wants to check is on the list of disabled dates
  //     return disabledDates.find(dDate => isSameDay(dDate, date));
  //   }
  // }


  const checkRoomDay = (date, userRoomList) => {

    let isUserRoomExist = false;
    const rooms = [];

    for (const room of userRoomList) {
      const roomDay = isSameDay(date, new Date(room.start));
      if (roomDay) {
        isUserRoomExist = true;
        rooms.push(room);
      }
    }

    if (isUserRoomExist) {
      return { isRoomDay: true, rooms: rooms };
    } else {
      return { isRoomDay: false, rooms: rooms };
    }
  };


  const hideRoomDayModalHandler = () => {
    setShowRoomDayModal(false);
    setSelectedDayRoomList([]);
  };

  const hideCreateRoomModalHandler = () => {
    setShowCreateRoomModal(false);
    // setSelectedDayRoomList([]);
  };

  const showCreateRoomModalHandler = () => {
    setShowCreateRoomModal(true);
    // setSelectedDayRoomList([]);
  };

  const getCreatedRoomDataHandler = (roomData) => {
    const addedList = [roomData].concat(userRoomList);
    dispatch('SET_USERROOM_LIST', addedList);

    hideRoomDayModalHandler();
  };

  let roomDayModalStyle = null;

  if (showCreateRoomModal) {
    roomDayModalStyle = {
      display: 'none'
    }
  }


  return (
  <Fragment>
    {showRoomDayModal && (
      <div style={roomDayModalStyle}>
        <Backdrop onCancel={hideRoomDayModalHandler}/>
        <ModalSimple>
          <RoomDayModalContent 
            selectedDayRoomList={selectedDayRoomList}
            hideRoomDayModalHandler={hideRoomDayModalHandler}
            value={value}
            sameDay={isSameDay(new Date(value), new Date())}
            showCreateRoomModalHandler={showCreateRoomModalHandler}
          />
        </ModalSimple>
      </div>
    )}

    {showCreateRoomModal && (
      <div>
        <Backdrop onCancel={hideCreateRoomModalHandler}/>
        <CreateRoomModal
          onCancel={hideCreateRoomModalHandler}
        >
          <NewMeetupForm 
            isInModal={true}
            calendardate={`${dateAndTime.format(new Date(value), 'YYYY-MM-DD')}`}
            getCreatedRoomDataHandler={getCreatedRoomDataHandler}
            hideCreateRoomModalHandler={hideCreateRoomModalHandler}
          />
        </CreateRoomModal>
    </div>
    )}
    
    <div>
      <Calendar
        onChange={onChange}
        value={value}

        // minDate={new Date()}
        onClickDay={onClickDayHandler}
        // tileClassName={({ activeStartDate, date, view }) => view === 'month' && date.getDay() === 3 ? "wednesday" : null}
        // tileContent={({ date, view }) => view === 'month' && date.getDay() === 0 ? <p>It's Sunday!</p> : null}
        tileContent={tileContentFn}
        locale={calLocale}

        maxDate={new Date(lastTime)}
      />
    </div>
  </Fragment>);
}

export default UserRoomCalendar;
