import { Fragment } from "react";
import { Link } from 'react-router-dom';
import i18next from 'i18next';
// import { useTranslation } from "next-i18next";

// import Backdrop from "../backdrop/Backdrop";
// import ModalSimple from "../modal/ModalSimple";
// import Language from '../ui/Language';
import classes from "./Footer.module.css";

function Footer(props) {
  // console.log('Footer.js-props', props);
  // const { t } = useTranslation("common");

  return (
    <Fragment>
      {/* <Language /> */}
      <footer className={classes.footer}>
        <div>
          <span>
            <Link to='/privacy-policy'>
              {i18next.t('privacyPolicy.text1', 
                'Privacy Policy'
              )}
            </Link>
          </span>
          <span className={classes.footerLink}>
            <Link to='/terms-of-use'>
              {i18next.t('termsOfUse.text1', 
                'Terms of use'
              )}
            </Link>
          </span>
          <span className={classes.footerLink}>
            <Link to='/contact'>
              {i18next.t('contact.text01', 'Contact Form')}
            </Link>
          </span>
        </div>

        <div className={classes.footerContent}>
          REMEET (part of SpaceEight)
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
