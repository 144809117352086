import { useEffect } from 'react';

import Footer from './Footer';
import Language from '../language/Language';
import SubscriptionNavigation from './SubscriptionNavigation';

import classes from './SubscriptionLayout.module.css';


function SubscriptionLayout(props) {
  // console.log('SubscriptionLayout.js-props', props);

  return (
    <div className={classes.AppContainer}>
      <SubscriptionNavigation />
      <main className={classes.main}>{props.children}</main>
      {/* <Language /> */}
      <Footer />
    </div>
  );
}

export default SubscriptionLayout;
