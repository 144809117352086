import { initStore } from './store';

const configureStore = () => {
    const actions = {
        ADD: (state, amount) => ({
            counter: state.counter + amount,
            clickNumber: state.clickNumber + 1,
        }),
        SUB: (state, amount) => ({
            counter: state.counter - amount,
            clickNumber: state.clickNumber + 1,
        }),
        MULTI: (state, amount) => ({
            counter: state.counter * amount,
            clickNumber: state.clickNumber + 1,
        }),
        DIV: (state, amount) => ({
            counter: state.counter / amount,
            clickNumber: state.clickNumber + 1,
        }),
        RESET_COUNTER: (state, amount) => ({
            counter: 0, 
            clickNumber: 0
        }),
    };

    initStore(actions, { counter: 0, clickNumber: 0 });
};

export default configureStore;