import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_USERROOM_LIST: (state, payload) => {
      return {
        userRoomStore: {
          ...state.userRoomStore,
          userRoomList: payload,
        }
      }
    },
    SET_USERROOM_LISTFILTER: (state, payload) => {
      // const filter = {
      //   filterStart: payload.filterStart,
      //   filterEnd: payload.filterEnd,
      // };
      return {
        userRoomStore: {
          ...state.userRoomStore,
          filterStart: payload.filterStart,
          filterEnd: payload.filterEnd,
        }
      }
    },
    SET_FILTEREDROOM_LIST: (state, payload) => {
      return {
        userRoomStore: {
          ...state.userRoomStore,
          filteredRoomList: payload,
        }
      }
    },
  };

  initStore(actions, {
    userRoomStore: {
      userRoomList: [],
      filterStart: 0,
      filterEnd: 0,
      filteredRoomList: [],
    }
  });
};

export default configureStore;
