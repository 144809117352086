import { Fragment, useState } from "react";
// import Link from "next/link";
// import { useRouter } from "next/router";
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';
import _ from 'lodash';

import Backdrop from "../backdrop/Backdrop";
import ModalSimple from "../modal/ModalSimple";
// import { useStore } from "../../hooks-store/store";
import classes from "./Language.module.css";

function Language(props) {
  // console.log('Language.js-props', props);
  // console.log(i18next.store.data);
  // const languageObject = props.i18nInfo ? props.i18nInfo.initialI18nStore : null
  const languageObject = i18next.store.data;
  // console.log(languageObject)
  let languageList = [];
  if (languageObject) {
    for (const key in languageObject) {
      // console.log(languageObject[key])
      languageList.push({
        CODE: languageObject[key].translations.CODE,
        LANGUAGE: languageObject[key].translations.LANGUAGE,
        content: languageObject[key].translations
      });
    }
  }
  languageList = _.sortBy(languageList, ['LANGUAGE'])
  // console.log(languageList)

  // const { t } = useTranslation("common");

  // const router = useRouter();

  // const [state, dispatch] = useStore();

  const [showModal, setShowModal] = useState(false);
  // const [localeList, setLocaleList] = useState([]);


  // const { pathname } = router;
	// console.log(pathname);
	// const classes = ['text-sm', 'md:text-base', 'm-0.5', 'p-0.5'];


  // useEffect(() => {
  //   if (state.i18nInfo && state.i18nInfo.userConfig) {
  //     setLocaleList(state.i18nInfo.userConfig.i18n.locales);
  //   }
  // }, [state.i18nInfo]);

  return (
    <Fragment>
      {showModal && 
        <Backdrop 
          // onCancel={() => {setShowModal(false)}}
        />
      }
      {showModal && (
        <ModalSimple>
          <div className={classes.languageClose}
            onClick={() => {setShowModal(false)}}
          >
            x
          </div>

          {
            <ul className={classes.languageList}>
              {languageList.map((ln, index) => {

                // const current = ['uppercase', i18next.language === ln ? 'text-blue-600' : ''];
                // const path = pathname.replace(/\[lang\]/i, ln);
                // const linkPath = `/${ln.CODE}`
                return (
                  <li key={ln.CODE}>
                    <div>
                      <span 
                        // className={classes.concat(current).join(' ').trim()}
                        onClick={() => {
                          setShowModal(false);
                          localStorage.setItem('userSelectLng', ln.CODE);
                          if (localStorage.getItem('lng') !== ln.CODE) {
                            i18next.changeLanguage(ln.CODE);
                            window.location.reload();
                          }
                        }}
                      > 
                        <span className={classes.languageCode}>{ln.CODE.toUpperCase()}</span> 
                        <span className={classes.languageCodes}>({ln.LANGUAGE})</span> 
                        {/* {i18next.t(ln.CODE)} */}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          }

        </ModalSimple>
      )}
      <div className={classes.languageBody} onClick={() => {setShowModal(true)}}>
        {/* Language: {router.locale.toUpperCase()} (Change) */}
        {i18next.t('generalText24')}: {i18next.language.toUpperCase()} ({i18next.t('generalText25')})
      </div>
    </Fragment>
  );
}

export default Language;
