import { Fragment, useRef, useState, useEffect } from "react";
// import { useRouter } from 'next/router';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Loader from "../../Loader/Loader";
import UserRoomListItem from "./UserRoomListItem";

import { useStore } from "../../../hooks-store/store";

import { BACKEND_URL } from "../../../App";

import classes from './UserRoomFilter.module.css';

function UserRoomFilter(props) {
  const { setCurrentPage } = props;
  // console.log(props);
  // console.log(i18next);

  const [store, dispatch] = useStore();
  // console.log('store-in-userRoomFilter.js', store);
  const userRoomList = store.userRoomStore.userRoomList;
  const filterStart = store.userRoomStore.filterStart;
  const filterEnd = store.userRoomStore.filterEnd;

  const [startYear, setStartYear] = useState();
  const [startMonth, setStartMonth] = useState();
  const [endYear, setEndYear] = useState();
  const [endMonth, setEndMonth] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const startEndInputs = {
    startYear: startYear,
    startMonth: startMonth,
    ednYear: endYear,
    endMonth: endMonth,
    filterStartDate: new Date(startYear, Number(startMonth) -1, 1),
    filterEndDate: new Date(endYear, endMonth, 1),
  };

  console.log(startEndInputs);

  // const startDate = new Date(startYear, startMonth, 1);
  // const endDate = new Date(endYear, endMonth, 1);
  // console.log(startDate, endDate);

  useEffect(() => {
    if (filterStart > 0 && filterEnd > 0) {
      const filterdList = getFilterdRoomList(filterStart, filterEnd, userRoomList);
      console.log(filterdList);

      dispatch('SET_FILTEREDROOM_LIST', filterdList);
    }
    if (filterStart === 0 && filterEnd === 0) {
      dispatch('SET_FILTEREDROOM_LIST', []);
    }
  },[filterStart, filterEnd]);

  // useEffect(() => {
  //   const lStart = startEndInputs.filterStartDate.getTime();
  //   const lEnd = startEndInputs.filterEndDate.getTime();
  //   console.log(lStart, lEnd);

  //   if ( (lStart < lEnd) && 
  //     (filterStart !== lStart || filterEnd !== lEnd)
  //   ) {
  //     dispatch('SET_USERROOM_LISTFILTER', {
  //       filterStart: lStart,
  //       filterEnd: lEnd,
  //     });
  //   }
  // },[startEndInputs]);


  const getFilterdRoomList = (fStart, fEnd, userRoomList) => {
    console.log(fStart, fEnd);
    const filterdList = userRoomList.filter(room => {
      return room.start > fStart && room.start < fEnd;
    });

    return filterdList;
  };

  const clearFilterHandler = () => {
    dispatch('SET_USERROOM_LISTFILTER', {
      filterStart: 0,
      filterEnd: 0,
    });
    setStartYear('');
    setEndYear('');
    setStartMonth('');
    setEndMonth('');

    setCurrentPage(1);
  };
  
  const setThisMonthHandler = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var nextFirstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);

    dispatch('SET_USERROOM_LISTFILTER', {
      filterStart: firstDay.getTime(),
      filterEnd: nextFirstDay.getTime(),
    });

    setCurrentPage(1);
  };

  const setLastMonthHandler = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

    dispatch('SET_USERROOM_LISTFILTER', {
      filterStart: lastFirstDay.getTime(),
      filterEnd: firstDay.getTime(),
    });

    setCurrentPage(1);
  };

  const setNextMonthHandler = () => {
    var date = new Date();
    const nextFirstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    const next2FirstDay = new Date(date.getFullYear(), date.getMonth() + 2, 1);
    console.log(nextFirstDay, next2FirstDay);

    dispatch('SET_USERROOM_LISTFILTER', {
      filterStart: nextFirstDay.getTime(),
      filterEnd: next2FirstDay.getTime(),
    });

    setCurrentPage(1);
  };

  const setPeriodHandler = () => {
    const lStart = startEndInputs.filterStartDate.getTime();
    const lEnd = startEndInputs.filterEndDate.getTime();
    console.log(lStart, lEnd);

    if ( (lStart < lEnd) && 
      (filterStart !== lStart || filterEnd !== lEnd)
    ) {
      dispatch('SET_USERROOM_LISTFILTER', {
        filterStart: lStart,
        filterEnd: lEnd,
      });

      setCurrentPage(1);
    }
  }


  let userRoomFilterBody = <div>filter-body</div>;
  
    userRoomFilterBody = (
      <div className={classes.roomFilter}>
        <button onClick={clearFilterHandler}>
          
          {i18next.t('userRoom.text10', 'Reset')}
        </button>
        <button onClick={setThisMonthHandler}>
          {i18next.t('userRoom.text11', 'This Month')}
        </button>
        <button onClick={setLastMonthHandler}>
          {i18next.t('userRoom.text12', 'Last Month')}
        </button>
        <button onClick={setNextMonthHandler}>
          {i18next.t('userRoom.text13', 'Next Month')}
        </button>

          <div>
            <div className={classes.roomFilterPeriod}>
              <input className={classes.roomFilterYearInput}
                type='text' 
                placeholder={`${i18next.t('userRoom.text14', 'start year')}`}
                value={startYear}
                onChange={(event) => { setStartYear(event.target.value);}}
              />
              <select className={classes.roomFilterMonthSelect}
                  value={startMonth}
                  onChange={event => {
                    setStartMonth(event.target.value);
                  }}
                >
                  <option value={''}>
                    {i18next.t('userRoom.text15', 'start month')}
                  </option>
                  {[1,2,3,4,5,6,7,8,9,10,11,12].map(sMonth => (
                    <option key={sMonth} value={sMonth}>
                      {sMonth}
                    </option>
                  ))}
              </select>
              <span className={classes.roomFilterPeriodSeparator}>
                {' ~ '}
              </span>
              <input className={classes.roomFilterYearInput}
                type='text' 
                placeholder={`${i18next.t('userRoom.text16', 'end year')}`}
                value={endYear}
                onChange={(event) => { setEndYear(event.target.value);}}
              />
              <select className={classes.roomFilterMonthSelect}
                value={endMonth}
                onChange={event => {
                  setEndMonth(event.target.value);
                }}
              >
                <option value={''}>
                  {i18next.t('userRoom.text17', 'end month')}
                </option>
                {[1,2,3,4,5,6,7,8,9,10,11,12].map(eMonth => (
                  <option key={eMonth} value={eMonth}>
                    {eMonth}
                  </option>
                ))}
              </select>
          </div>
          
          {startYear && endYear && startMonth && endMonth && (
            <div>
              <button onClick={setPeriodHandler}>
                {i18next.t('userRoom.text18', 'Get This Period')}
              </button>
            </div>
          )}
        
          </div>
      </div>
    );



  return (
  <Fragment>
      {userRoomFilterBody}
  </Fragment>);
}

export default UserRoomFilter;
