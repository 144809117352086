import { Fragment, useEffect } from "react";
import { Link } from 'react-router-dom';
import i18next from 'i18next';
// import { userLoginEmail } from "../../App";

import { useStore } from "../../hooks-store/store";
import { getSubscriptionState } from '../../utils/stripe';

import Card from "../../components/ui/Card";
import classes from "./UserInfo.module.css";

function UserInfo(props) {
  // console.log('Footer.js-props', props);
  // const { t } = useTranslation("common");

  const [state, dispatch] = useStore();

  const userLoginEmail = localStorage.getItem('email') ? localStorage.getItem('email') : '';
  
  const subscriptionLink = `/subscription?customer-email=${userLoginEmail}`

  const subscriptions = state.stripeData.subscriptions;
  const subscriptionStatus = getSubscriptionState(subscriptions);
  // console.log(subscriptionStatus);



  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <Fragment>

        <div className={classes.userInfo}>
          <div className={classes.userInfoTitle}>User Information Page</div>
          <div>
            <div className={classes.userInfoElements}>
              <span>email address: </span>
              <span className={classes.userInfoContent}>{userLoginEmail}</span>
            </div>
            <div className={classes.userInfoElements}>
              <span>sub-status: </span>
              <span className={classes.userInfoContent}>
                {subscriptionStatus ? subscriptionStatus : 'no-active-sub'}
              </span>
              {' '}
              {userLoginEmail &&
                <span>
                  <Link to={subscriptionLink}>
                    <button>manage-sub-link-button</button>
                  </Link>
                </span>
              }
            </div>
            
            <div className={classes.userInfoElements}>
              <span>other-info: </span>
              <span className={classes.userInfoContent}>other-info-content</span>
            </div>
            

          </div>
        </div>

    </Fragment>
  );
}

export default UserInfo;
