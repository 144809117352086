import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import Card from '../../../components/ui/Card';
import Loader from '../../../components/Loader/Loader';
import PricesDetail from '../../../components/subscription/prices/PricesDetail';
import { useStore } from '../../../hooks-store/store';
import { fetchPrices } from '../../../utils/stripe';

import { BACKEND_URL, SUBSCRIPTION_BACKEND_URL } from '../../../App';

import classes from './PricesTrialUpgrade.module.css';

const PricesTrialUpgrade = (props) => {
  // console.log(props);
  const lsCustomerId = localStorage.getItem('customerId');
  const lsCustomerEmail = localStorage.getItem('customerEmail'); 
  const lsToken = localStorage.getItem('token');

  const [state, dispatch] = useStore();
  // console.log(state);

  const [prices, setPrices] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [hasPastTrial, setHasPastTrial] = useState(true);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // const fetchPrices = async () => {
    //   // const {prices} = await fetch(BACKEND_URL + '/config').then(r => r.json());
    //   const {prices} = await fetch(BACKEND_URL + '/plan-prices').then(r => r.json());
      
    //   setPrices(prices);

    // };
    // fetchPrices();

    setIsLoading(true);

    fetchPrices()
      .then(res => {
        // console.log(res)
        // setPrices(res);
        setPrices(res.prices);
        setIsLoading(false);
      })
      .catch(err => {
        // console.log(err);
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    const listCanceledSubscriptions = async (customerId, token, email) => {
      setIsLoading(true);
      // const {subscriptions} = await fetch(BACKEND_URL + '/subscriptions').then(r => r.json());

        const { subscriptions, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/customer/list-customer-canceled-subscriptions', {
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customerId: customerId,
            email: email,
          }),
        }).then(r => r.json());
        
        // console.log(subscriptions);
        
        if (error) {
          // console.log(error);
          setIsLoading(false);
        }

        if (subscriptions) {
          let trialNum = 0;
          for (const sub of subscriptions.data) {
            // console.log(sub.metadata);
            if (sub.metadata.trial) {
              trialNum = trialNum + 1;
            }
          }

          // console.log(trialNum);
          if (trialNum === 0) {
            setHasPastTrial(false);
          }
          // setSubscriptions(subscriptions.data);
          setIsLoading(false);
        }
      }

      if (lsCustomerId) {
        listCanceledSubscriptions(lsCustomerId, lsToken, lsCustomerEmail);
      }
 
  }, []);


  const trialUpgradeSubscription = async (priceId) => {
    setIsLoading(true);

    const {
      subscriptionId, 
      clientSecret,
      // pendingClientSecret,
      error 
    } = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/trial-upgrade-subscription', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        customerId: lsCustomerId,
        trialSubscriptionId: props.location.state.subscription,
      }),
    }).then(r => r.json());

    // console.log(sub);
    // console.log(subscriptionId, clientSecret, error,
    //   // pendingClientSecret
    //   );
    
    // if (subscriptionId && (clientSecret || pendingClientSecret)) {
    if (subscriptionId && clientSecret) {
      setSubscriptionData({
        subscriptionId: subscriptionId, 
        clientSecret: clientSecret,
        // pendingClientSecret: pendingClientSecret
      });
      setIsLoading(false);
    }


    if (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };


  if(
    props.location.state && props.location.state.trialUpgrade 
    && subscriptionData && subscriptionData.clientSecret
    ) {
    return <Redirect to={{
      pathname: '/subscription/subscribe-trial-upgrade',
      state: {
        ...subscriptionData,
        trialSubscription: props.location.state.subscription  //// id
      }
    }} />
  }

  if(subscriptionData && subscriptionData.pendingClientSecret) {
    // return <Redirect to={{
    //   pathname: '/subscribe-trial',
    //   state: subscriptionData
    // }} />
    return window.location.reload();
  }

  if(subscriptionData && subscriptionData.clientSecret) {
    return <Redirect to={{
      pathname: '/subscribe',
      state: subscriptionData
    }} />
  }


  return (
    <div>
      <Card>
        <div className={classes.subscriptionCardContents}>
          <h2>
            {/* Select a subscription type */}
            {i18next.t('subText13', 'Select a subscription type')}	
          </h2>

          <div className="price-list">
            {prices.map((price) => {
              // console.log(price.recurring.trial_period_days)
              return (
                <div key={price.id} className={classes.planContainer}>
                  <h3>{price.product.name}</h3>

                  <p>
                    {/* ${price.unit_amount / 100} / month */}
                    ${price.unit_amount / 100} / {price.recurring.interval}
                  </p>

                  {/* <button 
                    disabled={isLoading}
                    onClick={() => createSubscription(price.id)}
                  >
                    Select
                  </button> */}

                  <button className={classes.subscriptionButton}
                    disabled={isLoading}
                    onClick={() => trialUpgradeSubscription(price.id)}
                  >
                    {/* Select */}
                    {i18next.t('subText14', 'Select')}
                  </button>

                  <PricesDetail 
                    price={price}
                    isLoading={isLoading}
                  />

                  <div>{error}</div>

                  {isLoading && <Loader />}
                </div>
              )
            })}
          </div>

          <div>{error}</div>
          {isLoading && <Loader />}

        </div>

      </Card>
    </div>
  );
}

export default withRouter(PricesTrialUpgrade);
