export const getRoom = (url, roomId, password, creatorPass) => {
  return new Promise((resolve, reject) => {
    fetch(url + "/room/get-room", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        roomId: roomId,
        password: password,
        creatorPass: creatorPass,
      }),
    })
      .then((res) => {
        console.log(res);

        if (res.ok) {
          return res.json();
        }

        if (res.status === 404) {
          // throw new Error("room-not-found");
          reject({
            message: "room-not-found",
            // type: "get-api-failed",
            // error: err
          });
        }

        return res.json().then((err) => {
          console.log(err);
          // throw new Error(data.message || 'Something went wrong')
          reject({
            // message: "Service is not available now",
            message: err.message || "Something went wrong",
            // type: "get-api-failed",
            // error: err
          });
        });
      })
      .then((resData) => {
        // console.log(resData);

        //// error end time is before now
        if (resData.data.end < Date.now()) {
          // throw new Error('room-expired');
          reject({
            message: "room-expired",
            // type: "get-api-failed",
            // error: err
          });
        }

        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};


export const createRoom = (url, meetupDataObj) => {
  return new Promise((resolve, reject) => {

    let headers = {
      "Content-Type": "application/json",
    };

    if (localStorage.getItem('token')) {
      headers = {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    }

    fetch(url + "/room", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        ...meetupDataObj,
        customerId: localStorage.getItem('customerId'),
      }),
    })
      .then((res) => {
        console.log(res);
        // return res.json();

        if (res.ok) {
          return res.json();
        }

        //// error handling
        return res.json().then((err) => {
          console.log(err);
          // throw new Error(data.message || 'Something went wrong')
          
          if (err.data && err.data.errorType) {
            reject({
              message: err.message || "Something went wrong",
              errorType: err.data.errorType,
              // error: err
            });
          }
          
          reject({
            message: err.message || "Something went wrong",
            // type: "get-api-failed",
            // error: err
          });
        });
      })
      .then((resData) => {
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const deleteRoom = (url, meetupDataObj) => {
  return new Promise((resolve, reject) => {
    fetch(url + "/room", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(meetupDataObj),
    })
      .then((res) => {
        console.log(res);

        if (res.ok) {
          return res.json();
        }

        return res.json().then((err) => {
          console.log(err);
          // throw new Error(data.message || 'Something went wrong')
          
          if (err.data && err.data.errorType) {
            reject({
              message: err.message || "Something went wrong",
              errorType: err.data.errorType,
              // error: err
            });
          }

          reject({
            // message: "Service is not available now",
            message: err.message || "Something went wrong",
            // type: "get-api-failed",
            // error: err
          });
        });
      })
      .then((resData) => {
        // console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const updateRoom = (url, meetupDataObj) => {
  return new Promise((resolve, reject) => {

    let headers = {
      "Content-Type": "application/json",
    };

    if (localStorage.getItem('token')) {
      headers = {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    }

    fetch(url + "/room", {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({
        ...meetupDataObj,
        customerId: localStorage.getItem('customerId'),
      }),
    })
      .then((res) => {
        console.log(res);

        if (res.ok) {
          return res.json();
        }


        return res.json().then((err) => {
          console.log(err);
          // throw new Error(data.message || 'Something went wrong')
          
                    
          if (err.data && err.data.errorType) {
            reject({
              message: err.message || "Something went wrong",
              errorType: err.data.errorType,
              // error: err
            });
          }
          
          reject({
            // message: "Service is not available now",
            message: err.message || "Something went wrong",
            // type: "get-api-failed",
            // error: err
          });
        });
      })
      .then((resData) => {
        // console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};