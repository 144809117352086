import { Fragment, useEffect } from "react";
import i18next from "i18next";

import Card from "../ui/Card";
import TrialInformationPrices from "./TrialInformationPrices";
import { useStore } from "../../hooks-store/store";
import { authSignupPageLink, authLoginPageLink } from "../../App";

import classes from "./TrialInformationContents.module.css";

import RemeetImage1 from '../../images/remeet-image-1.jpg';
import RemeetImage2 from '../../images/remeet-image-2.png';
import RemeetImage3 from '../../images/remeet-image-3.png';

function TrialInformationContent(props) {
  const { prices } = props;

  const [store, dispatch] = useStore();

  const userLoginEmail = localStorage.getItem("email");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let trialInfoBody;
  trialInfoBody = (
    <div>
      <div className={classes.trialInfoTitle}>
        REMEET {i18next.t("trialText22", "Video Meeting")}{" "}
        {i18next.t("subText16", "Free Trial Subscription")}
      </div>


      <div className={classes.trialInfoImageContainer}>
          <img className={classes.trialInfoImage}
            src={RemeetImage2}
            alt='remeet-img'
          />
          <img className={classes.trialInfoImage}
            src={RemeetImage3}
            alt='remeet-img'
          />
      </div>

      <div className={classes.trialInfoContentsTitle}>
        {i18next.t("trialText2", "Period of free trial subscription")}:
        <br />
        <div className={classes.trialInfoContentsContent}>
          {i18next.t("trialText3", "30 days")}
        </div>
      </div>
      <div className={classes.trialInfoContentsTitle}>
        <span className={classes.trialInfoContentsContent}>
          {i18next.t("trialText4", "No limit for usage")}
        </span>
        <br />
        <span>
          (
          {i18next.t(
            "trialText5",
            "You can use as many times as you want during trial period."
          )}
          )
        </span>
      </div>
      <div className={classes.trialInfoContentsTitle}>
        {i18next.t("trialText6", "Maximum participants for one meeting")}:
        <br />
        <span className={classes.trialInfoContentsContent}>100</span> <br />
        <span>({i18next.t("trialText7", "recommended less than 7")})</span>
      </div>
      <div className={classes.trialInfoContentsTitle}>
        {i18next.t("trialText8", "Maximum duration for one meeting")}:
        <br />
        <div className={classes.trialInfoContentsContent}>
          {i18next.t("trialText9", "6 hours")}
        </div>
      </div>
      <div className={classes.trialInfoContentsTitle}>
        {i18next.t("trialText34", "Credit Card is not required.")}
      </div>
      <div className={classes.trialInfoContentsTitle}>
        {i18next.t(
          "trialText10",
          "Room reservation is necessary before meeting."
        )}
      </div>

      <div className={classes.trialInfoContentsTitle}>
        {i18next.t(
          "trialText11",
          "Signup and login process using email is necessary before start of free trial subscription."
          )}
      </div>
      {!userLoginEmail && (
        <div>
          <a
            href={authSignupPageLink}
            // target="_blank" rel="nofollow noopener noreferrer"
          >
            <button className={classes.trialInfoButton}>
              {i18next.t("trialText32", "Signup")}
            </button>
          </a>
          <span>or</span>
          <a
            href={authLoginPageLink}
            // target="_blank" rel="nofollow noopener noreferrer"
          >
            <button className={classes.trialInfoButton}>
              {i18next.t("trialText31", "Login")}
            </button>
          </a>
        </div>
      )}
      {/* <div className={classes.trialInfoContentsTitle}>
        {i18next.t(
          "trialText13",
          "If you need to contniue after trial period, subscription is required using card payment."
        )}
      </div> */}
      {/* <div className={classes.trialInfoContentsTitle}>
        {i18next.t(
          "trialText14",
          "Email notification will be sent, before end of trial period."
        )}
      </div> */}
    </div>
  );

  return (
    <Fragment>

      {trialInfoBody}

      <TrialInformationPrices prices={prices} />

      {!userLoginEmail && (
        <div>
          <a
            href={authSignupPageLink}
            // target="_blank" rel="nofollow noopener noreferrer"
          >
            <button className={classes.trialInfoButton}>
              {i18next.t("trialText32", "Signup")}
            </button>
          </a>
          <span>or</span>
          <a
            href={authLoginPageLink}
            // target="_blank" rel="nofollow noopener noreferrer"
          >
            <button className={classes.trialInfoButton}>
              {i18next.t("trialText31", "Login")}
            </button>
          </a>
        </div>
      )}

    </Fragment>
  );
}

export default TrialInformationContent;
