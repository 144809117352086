import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import MobileNavigation from '../mobileNavigation/MobileNavigation';
import NavigationItems from '../navigationItems/NavigationItems';
import { useStore } from '../../../hooks-store/store';

import classes from './MainNavigation.module.css';
// import { style } from './MainNavigation-style';

function MainNavigation() {

  const [store, dispatch] = useStore();

  const [showMobileNav, setShowMobileNav] = useState(false);
  // console.log(store);

  // const { t } = useTranslation("common");
  const homeLink = `/`;
  const editLink = `/edit-room`;

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  
  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        <NavLink to={homeLink}>
          RBR
        </NavLink>
      </div>
      <nav>
        <section className={classes.mainNavList}>
          <NavigationItems
            setShowMobileNav={setShowMobileNav} 
          />
        </section>

        <div>
          <div className={classes.menuBtn}
            onClick={() => {setShowMobileNav(!showMobileNav)}}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          {showMobileNav && 
            <MobileNavigation
              setShowMobileNav={setShowMobileNav}
            />
          }
        </div>


      </nav>

    </header>
  );
}

export default MainNavigation;
