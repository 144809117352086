import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import { useStore } from '../../../hooks-store/store';

import Backdrop from '../../backdrop/Backdrop';
import NavigationItems from '../navigationItems/NavigationItems';


import classes from './MobileNavigation.module.css';
// import { style } from './MainNavigation-style';

function MobileNavigation(props) {
  const { setShowMobileNav } = props;

  const [store, dispatch] = useStore();
  // console.log(store);

  // const { t } = useTranslation("common");


  return (


        <div>
          <div
          // className={classes.mobileNav}
            className={classes.mobileNavigation}
          >
            {/* <span>edit-room</span>
            <span>new-room</span>
            <span>remeet-page</span> */}
            <NavigationItems 
              navType='mobile'
              setShowMobileNav={setShowMobileNav}
            />
          </div>
          <Backdrop 
            onCancel={() => {setShowMobileNav(false)}}
          />
        </div>

  );
}

export default MobileNavigation;
