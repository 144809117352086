import { Fragment, useEffect, useState } from "react";
import i18next from "i18next";

import Card from "../ui/Card";
import PricesDetail from "../subscription/prices/PricesDetail";
import { useStore } from "../../hooks-store/store";
import { authSignupPageLink } from "../../App";

import classes from "./TrialInformationPrices.module.css";

function TrialInformationPrices(props) {
  const { prices } = props;

  const [store, dispatch] = useStore();

  let pricesBody;
  pricesBody = (
    <div>
      <div>
        {i18next.t("trialText15", "Subscription plans after free trial subscription")}
      </div>

      {prices.map((price) => {
        // console.log(price.recurring.trial_period_days)
        return (
          <div key={price.id} className={classes.trialInfoPlanContainer}>
            <h2>{price.product.name}</h2>

            <p className={classes.trialInfoPlanPrice}>
              ${price.unit_amount / 100} / {price.recurring.interval}
            </p>

            <PricesDetail 
                price={price}
                // isLoading={isLoading}
            />
          </div>
        );
      })}
    </div>
  );

  return <Fragment>{pricesBody}</Fragment>;
}

export default TrialInformationPrices;
