import { Link } from "react-router-dom";
// import { useTranslation } from "next-i18next";
import i18next from "i18next";

import AboutRemeetApp from "./AboutRemeetApp";
import { useStore } from "../../../hooks-store/store";
import { authLoginPageLink, authSignupPageLink } from "../../../App";

import { logoutHandler } from "../../../utils/user";
import { getSubscriptionState } from "../../../utils/stripe";

import LoginIcon from '../../../images/icons/loginIcon-48.png';
import SignupIcon from '../../../images/icons/signupIcon-48.png';
import LogoutIcon from '../../../images/icons/logoutIcon-48.png';

import classes from "./SubNavigation.module.css";
// import { style } from './MainNavigation-style';

function SubNavigation() {
  const [store, dispatch] = useStore();
  // console.log(store);

  const currentUrl = new URL(window.location.href);
  const pathName = currentUrl.pathname;

  const userLoginEmail = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";
  // console.log(currentUrl, currentUrl.pathname);

  const subscriptionLink = `/subscription?customer-email=${userLoginEmail}`;

  const subscriptions = store.stripeData.subscriptions;
  const subscriptionStatus = getSubscriptionState(subscriptions);

  const logoutConfirm = () => {
    if (window.confirm("Is it no problem to logout?")) {
      // console.log('confirm true');
      dispatch("SET_AUTHSTATE", false);
      logoutHandler();
      window.location.reload();
    }
  };

  let subNavBody;
  if (userLoginEmail) {
    subNavBody = (
      <div>
        <div className={classes.subNavLogoutButton}>
          <button className={classes.subNavAuthButton} onClick={logoutConfirm}>
            <span className={classes.subNavAuthButtonIcon}>
              <img src={LogoutIcon} height="16" alt="login icon"/>
            </span> 
            <span>
              {i18next.t("trialText23", "Logout")}
            </span>
          </button>
        </div>
        <div>
          {i18next.t("trialText24", "email")}: {userLoginEmail}
          {/* <NavLink to="/user-info">
            <button>controller-button</button>
          </NavLink> */}
        </div>
        <div>
          {i18next.t("trialText25", "Your Subscription")}:{" "}
          {subscriptionStatus === "past_due" ? (
            <span>
              <span>{i18next.t("trialText26", "Payment faild ")}</span>{" "}
              <Link to={subscriptionLink}>
                ({i18next.t("trialText27", "Detail and Payment link")})
              </Link>
            </span>
          ) : (
            <span>
              <span>{subscriptionStatus}</span>{" "}
              <Link to={subscriptionLink}>
                ({i18next.t("trialText28", "Subscription Page")})
              </Link>
            </span>
          )}
          {/* {subscriptionStatus} */}
          {/* <div className={classes.subNavSpacer}/>
        <div>
          {userLoginEmail &&
            <span>
              <Link to={subscriptionLink}>
                <button className={classes.subNavAuthButton}>
                  Subscription Page
                </button>
              </Link>
            </span>
          }
        </div> */}
          {/* <div>
          <Link to="/trial-information">trial-info-page-link</Link>
        </div> */}
        </div>
      </div>
    );
  } else {
    subNavBody = (
      <div>
        <a href={authLoginPageLink}>
          <button className={classes.subNavAuthButton}>
            <span className={classes.subNavAuthButtonIcon}>
              <img src={LoginIcon} height="16" alt="login icon"/>
            </span> 
            <span>
              {i18next.t("trialText31", "Login")}
            </span>
          </button>
        </a>
        <a href={authSignupPageLink}>
          <button className={classes.subNavAuthButton}>
            <span className={classes.subNavAuthButtonIcon}>
              <img src={SignupIcon} height="16" alt="login icon"/>
            </span>
            <span>
              {i18next.t("trialText32", "Signup")}
            </span>
          </button>
        </a>
      </div>
    );
  }

  // if (pathName === '/user-info') {
  //   return null;
  // }

  return (
    <header className={classes.subNav}>
      {pathName !== "/user-info" && subNavBody}

      {pathName !== "/trial-information" && (
        <div className={classes.subNavTrialAd}>
          <Link to="/trial-information">
          <div className={classes.subNavTrialAdTitle}>
            {i18next.t("trialText33", "Start Free Trial Subscription for 30 days")}
          </div>
            </Link>
          <div>
            <Link to="/trial-information">
              {i18next.t("trialText18", "Free Trial Subscription Info Page")}
            </Link>
          </div>

          <div>
            <AboutRemeetApp />
          </div>
        </div>
      )}
    </header>
  );
}

export default SubNavigation;
