import { Fragment, useRef, useState, useEffect } from "react";
// import { useRouter } from 'next/router';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Card from "../ui/Card";
import UserRoomCalendar from "./UserRoomCalendar/UserRoomCalendar";
import UserRoomList from "./UserRoomList/UserRoomList";
// import { createRoom } from '../../utils/meetup';
// import { createTextContent } from '../../utils/mail';
import { getSubscriptionState, getCurrentSubscription } from '../../utils/stripe';

import { useStore } from "../../hooks-store/store";

import { subscriptionMiniPriceId } from '../../App';

import classes from './UserRoom.module.css';

function UserRoom(props) {
  // console.log(props);
  // console.log(i18next);

  // const nowShortISOTime = createShortISOTime(Date.now());
  // const maxDuration = 1000 * 60 * 60 * 24; // for example 1 day
  // const allowStartTime = createShortISOTime(
  //   Date.now() + 1000 * 60 * 60 * 24 * 90
  // ); // for example 90 days later

  // const hourList = [
  //   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 
  //   13, 14, 15, 16,17, 18, 19, 20, 21, 22, 23
  // ];

  const userLoginEmail = localStorage.getItem('email') ? localStorage.getItem('email') : '';


  const [store, dispatch] = useStore();
  // const userRoomList = store.userRoomStore.userRoomList;

  // const router = useRouter();

  // const { t } = useTranslation("common");

  // const [roomConfig, setRoomConfig] = useState(undefined);

  const roomConfig = store.roomConfig;

  const subscriptions = store.stripeData.subscriptions;
  const subscriptionStatus = getSubscriptionState(subscriptions);
  const currentSubscription = getCurrentSubscription(subscriptions);
  // console.log('currentSubscription', currentSubscription);

  let haveActiveSubscription = false;
  if (subscriptionStatus === 'active' || subscriptionStatus === 'trialing') {
    haveActiveSubscription = true;
  }

  let isMiniSubscripiton = false;
  if (currentSubscription && currentSubscription.plan.id === subscriptionMiniPriceId) {
    isMiniSubscripiton = true;
  }

  let trialEndTime;
  if (subscriptionStatus === 'trialing' && currentSubscription) {
    trialEndTime = currentSubscription.trial_end * 1000;
  }
  // console.log('trialEndTime', trialEndTime);

  // console.log(currentSubscription);

  // console.log(subscriptionStatus);

  //// initial load path did not work on deploy why?...
  // useEffect(() => {
  //   const currentUrl = new URL(window.location.href);
  //   // console.log(currentUrl, currentUrl.pathname);
  //   if (currentUrl.pathname === '/edit-room') {
  //     router.push('/edit-room');
  //   }
  // },[]);


  return (
  <Fragment>
    <div className={classes.userRoomContainer}>
      <UserRoomCalendar />
      <div className={classes.userRoom}>

        <Card>
          <UserRoomList />
        </Card>
      </div>
    </div>
  </Fragment>);
}

export default UserRoom;
