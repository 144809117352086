import dateAndTime from 'date-and-time';

// // create "2017-06-01T08:30" format from Date number
export const createShortISOTime = (timeNum) => {
  const isoTime = new Date(timeNum).toISOString();
  return isoTime.split(":")[0] + ":" + isoTime.split(":")[1];
};

export const createLocalTime = (date, time) => {
  // console.log("getTimezoneOffset", new Date().getTimezoneOffset());
  const offset = new Date().getTimezoneOffset();
  let zoneValue;
  if (new Date().getTimezoneOffset() < 0) {
    if (new Date().getTimezoneOffset() / 60 > -10) {
      zoneValue = `+0${(offset / 60) * -1}:00`;
    } else {
      zoneValue = `+${(offset / 60) * -1}:00`;
    }
  }
  if (new Date().getTimezoneOffset() > 0) {
    if (new Date().getTimezoneOffset() / 60 >= 10) {
      zoneValue = `-${offset / 60}:00`;
    } else {
      zoneValue = `-0${offset / 60}:00`;
    }
  }
  if (new Date().getTimezoneOffset() === 0) {
    zoneValue = `+00:00`;
  }

  return date + "T" + time + ":00" + zoneValue;
};

export const transformLocaleDate = (date) => {
  const localeString = new Date(date).toLocaleString();
  // console.log(date, localeString);
  const dateEls = localeString.split(',')[0].split('/');

  if (Number(dateEls[0]) <= 9) {
    return dateEls[2] + '-0' + dateEls[0] + '-' + dateEls[1];
  } else {
    return dateEls[2] + '-' + dateEls[0] + '-' + dateEls[1];
  }
}

export const transformLocaleTime = (date) => {
  const localeToString = new Date(date).toString();
  const timeEl = localeToString.split(' ')[4];
  // return timeEl; //....
  return timeEl.split(':')[0] + ':' + timeEl.split(':')[1];
}

export const transFormLocaleDateTime = (date) => {
  return transformLocaleDate(date) + ', ' + transformLocaleTime(date);
};

export const isValidEmail = (value) =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value
  );

export const dateTimeDisplayOutput = (date, dateTime) => {
  // const outPut = date + ', ' + new Date(dateTime).toTimeString().split(' ')[0]
  //                 + ' (' + new Date(dateTime).toLocaleTimeString() + ')';
  
  const dateIn = new Date(dateTime);
  // console.log(dateAndTime.format(dateIn, 'YYYY-MM-DD HH:mm'));
  // console.log(dateAndTime.format(dateIn, 'YYYY-MM-DD hh:mm A')); 
  
  const time24h = dateAndTime.format(dateIn, 'YYYY-MM-DD HH:mm');
  // const time12h = dateAndTime.format(dateIn, 'YYYY-MM-DD hh:mm A');
  const time12h = dateAndTime.format(dateIn, 'hh:mm A');
  return `${time24h} (${time12h})`

  // const time24 = new Date(dateTime).toTimeString();
  // const xmTime = new Date(dateTime).toLocaleTimeString();
  // const outPut = `${date}, ${time24.split(':')[0]}:${time24.split(':')[1]}`
  //  + ` (${xmTime.split(':')[0]}:${xmTime.split(':')[1]} ${xmTime.split(':')[2].split(' ')[1]})`
  // return outPut
}

export const getApi = (url) => {
  return new Promise((resolve, reject) => {
    fetch(`${url}/example/`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("get api failed!");
        }

        return res.json();
      })
      .then((resData) => {
        // eslint-disable-next-line no-console
        // console.log(resData);

        resolve({ message: "get api success", data: resData.data });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        // console.log(err);
        // reject({ message: "Get api failed", error: err });
        reject({ 
          // message: "Service is not available now",
          message: 'get-api-failed',
          // type: "get-api-failed",
          error: err 
        });
      });
  });
};

export const getRoomConfig = (url) => {
  return new Promise((resolve, reject) => {
    fetch(`${url}/room/config`)
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("get config failed!");
        }

        return res.json();
      })
      .then((resData) => {
        // eslint-disable-next-line no-console
        // console.log(resData);

        resolve({ message: "get config success", data: resData.data });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        // console.log(err);
        // reject({ message: "Get api failed", error: err });
        reject({ 
          // message: "Service is not available now",
          message: 'get-api-failed',
          // type: "get-api-failed",
          error: err  
      });
      });
  });
};

export const sendRoomCreateMail = (url, roomData, textContent, subjectContent) => {
  return new Promise((resolve, reject) => {
    fetch(url + "/send-mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        roomData: roomData,
        textContent: textContent,
        subjectContent: subjectContent,
      }),
    })
      .then((res) => {
        // console.log(res);
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("send email failed!");
        }
        return res.json();
      })
      .then((resData) => {
        // console.log(resData);
        resolve(resData);
      })
      .catch((err) => {
        // console.log(err);
        // reject(err);
        reject({ message: 'send-mail-failed', error: err });
      });
  });
};

export const matchBrowserLanguage = (i18nInfo) => {
  let detectedLanguage = navigator.languages[0];
  // console.log(detectedLanguage);

  const lngObj = i18nInfo.initialI18nStore;
  // const lngObj = i18nInfoStore;
  // console.log(lngObj,lngObj[detectedLanguage]);

  if (!lngObj[detectedLanguage]) {
    detectedLanguage = navigator.languages[0].split("-")[0];
    if (!lngObj[navigator.languages[0].split("-")[0]]) {
      detectedLanguage = "en";
    }
  }

  // console.log(detectedLanguage);
  return detectedLanguage;
};

//// require router (useRouter())
export const setLanguage = (i18nInfo, router) => {
  
  //// set user selected language as page language if user already selected
  const userSelectLng = localStorage.getItem("userSelectLng");
  
  if (userSelectLng && userSelectLng !== i18nInfo.initialLocale) {
    router.push(`${router.pathname}`, `${router.pathname}`, {
      locale: userSelectLng,
    });
    return;
  }

  //// no user selected langugate, find browser language matches with i18n locales
  let detectedLanguage = navigator.languages[0];
  const lngObj = i18nInfo.initialI18nStore;

  if (!lngObj[detectedLanguage]) {
    detectedLanguage = navigator.languages[0].split("-")[0];
    if (!lngObj[navigator.languages[0].split("-")[0]]) {
      detectedLanguage = "en";
    }
  }

  //// set found browser language as page language
  if (!userSelectLng && i18nInfo.initialLocale !== detectedLanguage) {
    router.push(`${router.pathname}`, `${router.pathname}`, {
      locale: detectedLanguage,
    });
  }
};

export const matchBrowserLng = (i18nLngObj) => {
  let detectedLanguage = navigator.languages[0];
  // console.log(detectedLanguage);

  // const lngObj = i18nInfo.initialI18nStore;
  const lngObj = i18nLngObj;
  // const lngObj = i18nInfoStore;
  // console.log(lngObj,lngObj[detectedLanguage]);

  if (!lngObj[detectedLanguage]) {
    detectedLanguage = navigator.languages[0].split("-")[0];
    if (!lngObj[navigator.languages[0].split("-")[0]]) {
      detectedLanguage = "en";
    }
  }

  // console.log(detectedLanguage);
  return detectedLanguage;
};
