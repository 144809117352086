import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Link, withRouter, useHistory } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import Card from "../../../components/ui/Card";
import Loader from "../../../components/Loader/Loader";
import { useStore } from "../../../hooks-store/store";
import { getCustomerSubscriptions } from "../../../utils/stripe";

import { BACKEND_URL, SUBSCRIPTION_BACKEND_URL } from "../../../App";

import classes from "./Subscribe.module.css";

const Subscribe = ({ location }) => {
  // console.log(location);

  const CARD_OPTIONS = {
    // iconStyle: "solid",
    // value: {postalCode: '94110'},
    hidePostalCode: true,
    hideIcon: true,
    // disabled: true,
    style: {
      // base: {
      //   iconColor: "#c4f0ff",
      //   color: "#fff",
      //   fontWeight: 500,
      //   fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      //   fontSize: "16px",
      //   fontSmoothing: "antialiased",
      //   ":-webkit-autofill": {
      //     color: "#fce883"
      //   },
      //   "::placeholder": {
      //     color: "#87bbfd"
      //   }
      // },
      // invalid: {
      //   iconColor: "#ffc7ee",
      //   color: "#ffc7ee"
      // },
    },
  };

  const history = useHistory();

  const [state, dispatch] = useStore();
  // console.log(state);

  // Get the lookup key for the price from the previous page redirect.
  const [clientSecret] = useState(location.state.clientSecret);
  const [subscriptionId] = useState(location.state.subscriptionId);

  const [pendingClientSecret] = useState(location.state.pendingClientSecret);

  const [name, setName] = useState("");
  const [messages, _setMessages] = useState("");
  const [paymentIntent, setPaymentIntent] = useState();

  const [priceData, setPriceData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSubscription = async () => {
      const { subscription, error } = await fetch(
        SUBSCRIPTION_BACKEND_URL + "/subscription/subscription",
        {
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId,
          }),
        }
      ).then((r) => r.json());

      // console.log(subscription, subscription.plan, 'xx', subscription.items.data[0].price.id);

      const priceData = state.stripeData.prices.find((ele) => {
        // console.log(ele.id);
        return ele.id === subscription.plan.id;
      });
      setPriceData(priceData);
      // console.log(priceData);
    };

    if (state.stripeData.prices.length > 0) {
      fetchSubscription();
    }
  }, [state.stripeData.prices]);

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return "";
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    // console.log(messages);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // //Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name,
          },
        },
      }
    );

    if (error) {
      // show error and collect new card details.
      setMessage(error.message);
      setIsLoading(false);
      return;
    }
    setPaymentIntent(paymentIntent);
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    return <Redirect to={{ pathname: "/subscription/account" }} />;
  }

  return (
    <>
      {/* <h1>Subscribe</h1>

      <p>
        Try the successful test card: <span>4242424242424242</span>.
      </p>

      <p>
        Try the test card that requires SCA: <span>4000002500003155</span>.
      </p>

      <p>
        Use any <i>future</i> expiry date, CVC,5 digit postal code
      </p>

      <hr /> */}
      <Card>
        <div className={classes.subscriptionCardContents}>
          <h2>{i18next.t("subText34", "Subscribe")}</h2>
          <p className={classes.subscribeInfo}>
            {/* subscription-info */}
            {/* lookup_key: {priceData && priceData.lookup_key}
            <br /> */}
            {/* Type: {priceData && priceData.product.name} */}
            {i18next.t("subText4", "Type")}:{" "}
            {priceData && priceData.product.name}
          </p>
          <p className={classes.subscribeInfo}>
            {/* Price:{" "} */}
            {i18next.t("subText5", "Price")}:{" "}
            {priceData &&
              `${
                priceData.unit_amount / 100
              } ${priceData.currency.toUpperCase()} / ${
                priceData.recurring.interval
              }`}
          </p>

          <hr />

          <form onSubmit={handleSubmit}>
            <div className={classes.subscribeFormTitle}>
              {i18next.t("subText35", "Please Enter Your Card Info")}
            </div>

            <div className={classes.subscribeFormLabel}>
              {i18next.t("subText36", "Card Info")}
            </div>

            <div className={classes.subscribeFormCardElement}>
              <CardElement
                options={CARD_OPTIONS}
                onChange={() => {
                  _setMessages("");
                }}
              />
            </div>

            <div>
              <label className={classes.subscribeFormLabel}>
                {/* Full name on Credit Card */}
                {i18next.t("subText22", "Full name on Credit Card")}
              </label>
              <input
                className={classes.subscribeFormInput}
                type="text"
                id="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  _setMessages("");
                }}
              />
            </div>

            <div>
              <button
                className={classes.subscriptionButton}
                disabled={isLoading || !name}
              >
                {/* Subscribe */}
                {i18next.t("subText23", "Subscribe")}
              </button>
            </div>

            <div>{messages}</div>

            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              // <Link
              //   to={{
              //       pathname: "/account",
              //       // state: { data: props.subscription, },
              //     }}
              //   >
              //     <button>back-to-account-button</button>
              // </Link>
              <span></span>
            )}
          </form>
        </div>
      </Card>
    </>
  );
};

export default withRouter(Subscribe);
