import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import classes from './SubscriptionNavigation.module.css';
// import { style } from './MainNavigation-style';

function SubscriptionNavigation() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  
  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        REMEET {i18next.t("trialText28", "Subscription Page")}
      </div>
      <nav>
        <ul>
        </ul>
      </nav>
    </header>
  );
}

export default SubscriptionNavigation;
