import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";

import { authSignupPageLink } from "../../../App";

import classes from "./AboutRemeetApp.module.css";

import RemeetImage1 from '../../../images/remeet-image-1.jpg';
import RemeetImage2 from '../../../images/remeet-image-2.png';
import RemeetImage3 from '../../../images/remeet-image-3.png';

function AboutRemeetApp(props) {

  const [showAboutRemeet, setShowAboutRemeet] = useState(false);

  return (
    <Fragment>
      <div>
        <div className={classes.aboutRemeetTitle}>
          <span onClick={() => { setShowAboutRemeet(!showAboutRemeet); }}>
            {i18next.t('aboutApp.text01','About video App')}
            {' '}
            (REMEET: {i18next.t('aboutApp.text02','video talk and meeting')}) &#9662;
          </span>
        </div>

        {showAboutRemeet && (
          <div className={classes.aboutRemeetContent}>
            <p>
              {i18next.t('aboutApp.text03', 'REMEET is a simple video meeting and talk application.')} {i18next.t('aboutApp.text04', 'It contains text chat, screen sharing, file sharing.')}
            </p>
            <p>
              {i18next.t('aboutApp.text05', 'Users of REMEET can communicate with multiple people at the same time.')} 
              {' '}
              {i18next.t('aboutApp.text06', 'That is suitable for various situations such as talking with remote people, remote work, and remote meeting.')}
            </p>
            <p>
              {i18next.t('aboutApp.text07', 'Up to two participants, users can start video talk any time,')}
              {' '}
              {i18next.t('aboutApp.text08', 'after room reservation in this page.')}
            </p>
            <p>
              {i18next.t('aboutApp.text09', 'For more than three participants,')}
              {' '}
              {i18next.t('aboutApp.text10', 'there is free trial period for 30 days.')}
            </p>

            <div className={classes.aboutRemeetImageContainer}>
              <img className={classes.aboutRemeetImage}
                src={RemeetImage1}
                alt='remeet-img'
              />
              <img className={classes.aboutRemeetImage}
                src={RemeetImage3}
                alt='remeet-img'
              />
            </div>
          </div>
        )}

      </div>
    </Fragment>
  );
}

export default AboutRemeetApp;
