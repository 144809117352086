import { initStore } from './store';

const configureStore = () => {
    const actions = {
        // ADD: (state, amount) => ({
        //     counter: state.counter + amount,
        //     clickNumber: state.clickNumber + 1,
        // }),
        SET_I18NEXT: (state, payload) => {
          return {
            ...state,
            i18n: payload,
          }
        },
        SET_ROOMCONFIG: (state, payload) => {
          return {
            ...state,
            // roomConfig: payload,
            roomConfig: {
              ...payload,
              lastGetDate: Date.now(),
            },
           }
        },
        SET_AUTHSTATE: (state, payload) => {
          return {
            ...state,
            isAuth: payload,
          }
        },
        SHOW_NOTIFICATION: (state, payload) => {
          const notifyContent = {
            status: payload.status,
            title: payload.title,
            message: payload.message,
          };
          return { 
            notification: notifyContent, 
            i18n: state.i18next,
          };
        },
        CLEAR_NOTIFICATION: (state, payload) => {
          const notifyContent = {
            status: null,
            title: null,
            message: null,
          };
          return { 
            notification: notifyContent,
            i18n: state.i18next,
           };
        },
    };

    initStore(actions, { 
      i18n: undefined,
      roomConfig: undefined,
      isAuth: false,
      notification: {
        status: null,
        title: null,
        message: null,
      },
    });
};

export default configureStore;