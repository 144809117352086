import { Fragment, useState, useEffect } from "react";
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Card from "../../components/ui/Card";
import Loader from "../../components/Loader/Loader";

import { getApi } from "../../utils/new-meetup";
import { getRoom } from '../../utils/meetup';
import classes from "./GetMeetupForm.module.css";
// import { style } from './GetMeetupForm-style';

import { BACKEND_URL } from "../../App";

function GetMeetupForm(props) {

  const { qRoomId, qPassword, qCreatorPass } = props;

  // const { t } = useTranslation("common");
  // console.log(props, qRoomId, qPassword, qCreatorPass);
  
  const [getRoomNameInput, setGetRoomNameInput] = useState("");
  const [getPasswordInput, setGetPasswordInput] = useState("");
  const [getCreatorPassInput, setGetCreatorPassInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);


  //// preset room name if query param roomid exist
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const roomParam = currentUrl.searchParams.get('roomid');
    
    if (roomParam) {
      setGetRoomNameInput(roomParam);
    }
  },[]);

  //// delete error when input is changed
  useEffect(() => {
    setError('');
  },[getRoomNameInput, getPasswordInput, getCreatorPassInput]);

  useEffect(() => {
    if (qRoomId && qPassword && qCreatorPass) {
      getRoomInfoHandler(
        qRoomId,
        qPassword,
        qCreatorPass,
      );
    }
  },[]);

  const getRoomNameInputHandler = (event) => {
    setGetRoomNameInput(event.target.value.toLowerCase());
  };

  const getPasswordInputHandler = (event) => {
    setGetPasswordInput(event.target.value);
  };

  const getcreatorPassInputHandler = (event) => {
    setGetCreatorPassInput(event.target.value);
  };

  const getRoomInfoHandler = (roomId, password, creatorPass) => {
    // console.log(roomInfo);
    setIsLoading(true);

    getApi(BACKEND_URL)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);

        return getRoom(BACKEND_URL, roomId, password, creatorPass);
      })
      .then((resData) => {
        console.log(resData);

        props.setGotRoomInfo(resData.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);


        if (err.message === 'get-api-failed') {
          setError(i18next.t("roomFormText19", 
          'Service is not available now'
          ));

        }  
        // if (err.message === 'room-not-found') {
        //   setError('not found');
        //   setIsLoading(false);
        //   return;
        // }
        if (err.message === 'room-expired') {
          setError(i18next.t("roomFormText56",
            'Room is already expired'
          ));
        }
        else {
          setError(i18next.t("roomFormText23",
          'Get room information failed! Please check your Inputs. Or room is already expired'
          ));

        }
        // setError(err.message);
        setIsLoading(false);
      });
  };

  let roomGetBody;
  roomGetBody = <div></div>;

  let body;
  body = (
    <Card>

      <div className={classes.form}>
        {/* <div>Room-get-form</div> */}
        <div className={classes.control}>
          <label htmlFor="room-name">
            {/* Room Name (room's name 8-20 character length of lowercase or number) */}
            Room Name {i18next.t("roomFormText2")}
          </label>
          <input
            type="text"
            required
            value={getRoomNameInput}
            onChange={getRoomNameInputHandler}
            placeholder=""
          />
        </div>
        <div className={classes.control}>
          <label htmlFor="room-name">
            {/* Passowrd (password for room) */}
            Password ({i18next.t("roomFormText17")})
          </label>
          <input
            type={showPassword ? "" : "password"}
            required
            value={getPasswordInput}
            onChange={getPasswordInputHandler}
            placeholder=""
          />
        </div>
        <div className={classes.passwordCheckboxContainer}>
          <input className={classes.passwordCheckbox}
            type="checkbox" 
            id="checkbox"
            onClick={() => {setShowPassword(!showPassword)}}
          />
          <label htmlFor="checkbox">
            {/* Display entered password */}
            {i18next.t("roomFormText9")}
          </label>
        </div>
        <div className={classes.control}>
          <label htmlFor="room-name">
            {/* creatorPass (creatorPass in Email for room) */}
            creatorPass ({i18next.t("roomFormText18")})
          </label>
          <input
            type=""
            required
            value={getCreatorPassInput}
            onChange={getcreatorPassInputHandler}
            placeholder=""
          />
        </div>
        <div className={classes.actions}>
          <button
            disabled={isLoading || !getRoomNameInput || !getPasswordInput || !getCreatorPassInput}
            onClick={() => {
              getRoomInfoHandler(
                getRoomNameInput,
                getPasswordInput,
                getCreatorPassInput
              );
            }}
          >
            {/* Get Room information */}
            {i18next.t("roomFormText10")}
          </button>
        </div>
        <div className={classes.resultMessage}>
          {error}
        </div>
        {isLoading && (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </Card>
  );

  return (
    <Fragment>
      {roomGetBody}
      {body}
    </Fragment>
  );
}

export default GetMeetupForm;
