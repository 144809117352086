import MainNavigation from './mainNavigation/MainNavigation';
import Footer from './Footer';
import Language from '../language/Language';
import classes from './Layout.module.css';
import GetConfig from '../getConfig';
// import SubNavigation from './subNavigation/SubNavigation';
import SubNavigation from './subNavigation/SubNavigation';
import { useStore } from '../../hooks-store/store';

function Layout(props) {
  // console.log('Layout.js-props', props);
  const { hideSubNavigation } = props;

  const [store, dispatch] = useStore();

  if (!store.roomConfig) {
    return <GetConfig />
  }

  return (
    <div className={classes.AppContainer}>
      <MainNavigation />
      {!hideSubNavigation && (
        <SubNavigation />
      )}
      <GetConfig />
      <main className={classes.main}>{props.children}</main>
      <Language />
      <Footer />
    </div>
  );
}

export default Layout;
