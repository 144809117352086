import { Fragment } from "react";
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import { dateTimeDisplayOutput } from "../../utils/new-meetup";
import classes from "./NewMeetupModalContent.module.css";

function NewMeetupModalContent(props) {
  // console.log('NewMeetupModalContent.js-props', props);
  // const { t } = useTranslation("common");
  
  return (
    <Fragment>
      {/* new-meetup-modal-content */}
      <div className={classes.control}>
        <div className={classes.title}>
          {/* Please Confirm Your Inputs */}
          {i18next.t("roomFormText15")}
        </div>
        <div>
          <div className={classes.label}>
            {/* Room Name (room's name) */}
            Room Name ({i18next.t("roomFormText12")})  
          </div>
          <div>{props.roomData.roomId}</div>
        </div>
        <div>
          <div className={classes.label}>
            {/* Start Time (Start Date and Time) */}
            {i18next.t("roomFormText3")} (Start Date and Time)
          </div>
          <div>
            {/* {props.startDateInput}
            {", "}
            {props.startHourInput}
            {":"}
            {props.startMinuteInput} (
            {new Date(props.roomData.start).toLocaleString()}) */}
            {dateTimeDisplayOutput(props.startDateInput, props.roomData.start)}
          </div>
        </div>
        <div>
          <div className={classes.label}>
            {/* End Time (End Date and Time) */}
            {i18next.t("roomFormText4")} (End Date and Time)
          </div>
          <div>
            {/* {props.endDateInput}
            {", "}
            {props.endHourInput}
            {":"}
            {props.endMinuteInput} (
            {new Date(props.roomData.end).toLocaleString()}) */}
            {dateTimeDisplayOutput(props.endDateInput, props.roomData.end)}
          </div>
        </div>
        <div>
          <div className={classes.label}>
            {/* Maximum Participatns (Maximum number of participants) */}
            {i18next.t("roomFormText5")} (Maximum number of participants)
          </div>
          <div>{props.roomData.maxPeople}</div>
        </div>
        <div>
          <div className={classes.label}>
            {/* Email address (Email address) */}
            {i18next.t("roomFormText6")} (Email address)
          </div>
          <div>{props.roomData.email}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default NewMeetupModalContent;
