import { Fragment, useEffect } from "react";

import i18next from 'i18next';

import classes from "./TermsOfUse.module.css";

function TermsOfUse(props) {
  // console.log('Footer.js-props', props);
  // const { t } = useTranslation("common");
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <Fragment>
      <div className={classes.termsOfUse}>
        <h4>
          {i18next.t('termsOfUse.text1', 
            'Terms of use'
          )}
        </h4>
        <h4>
          {i18next.t('termsOfUse.text2', 
            'Privacy'
          )}
          </h4>
          {i18next.t('termsOfUse.text3', 
            'Your privacy is important to us. Please read the privacy policy'
          )}

        <br />
        <h4>
          {i18next.t('termsOfUse.text6', 
            'Your own responsibilities'
          )}
          </h4>
          {i18next.t('termsOfUse.text7', 
            'You, the user, are solely responsible for ensuring your own compliance with laws and taxes in your jurisdiction. You are solely responsible for your own security.'
          )}
        {/* <br /> */}
        <br />
        <h4>
          {i18next.t('termsOfUse.text8', 
            'Disclaimer of warranty'
          )}
          </h4>
          {i18next.t('termsOfUse.text9', 
            'This site is provided on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND.'
          )}
        <br />
        <br />
        {/* {props.accept ? <div>
          By pressing "
          {i18next.t('termsOfUse.text21')}
          <strong>
            Accept and Continue
            {i18next.t('termsOfUse.text22')}
            </strong>
          ", you acknowledge that you have read this document and agree to these terms of use.
          {i18next.t('termsOfUse.text23')}
          <div className="Session__tos__next">
            <button className="s-button" onClick={props.accept}>
              Accept and Continue
              {i18next.t('termsOfUse.text24')}
              </button>
          </div>
        </div> : null} */}
      </div>
    </Fragment>
  );
}

export default TermsOfUse;
