import { Fragment, useState, useEffect } from "react";
// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import { dateTimeDisplayOutput } from "../../utils/new-meetup";

import classes from "./GetMeetupContent.module.css";

function GetMeetupContent(props) {
  // console.log(props);

  // const { t } = useTranslation("common");

  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    createGotStartFormat();
    createGotEndFormat();
  },[]);

  const createGotStartFormat = () => {
    const dateElementList = new Date(props.roomData.start).toLocaleDateString().split('/');
    if (Number(dateElementList[0]) < 10) {
      dateElementList[0] = '0' + dateElementList[0];
    }
    if (Number(dateElementList[1]) < 10) {
      dateElementList[1] = '0' + dateElementList[1];
    }
  
    const dateFormated = dateElementList[2] + '-' + dateElementList[0] + '-' + dateElementList[1];
    console.log(dateFormated);
    setStartDate(dateFormated);
  
  
    const timeElement = new Date(props.roomData.start).toString().split(' ')[4];
    // const hourElement = timeElement.split(':')[0];
    // const minuteElement = timeElement.split(':')[1];
    console.log(timeElement);
    setStartTime(timeElement);
  }

  const createGotEndFormat = () => {
    const dateElementList = new Date(props.roomData.end).toLocaleDateString().split('/');
    if (Number(dateElementList[0]) < 10) {
      dateElementList[0] = '0' + dateElementList[0];
    }
    if (Number(dateElementList[1]) < 10) {
      dateElementList[1] = '0' + dateElementList[1];
    }
  
    const dateFormated = dateElementList[2] + '-' + dateElementList[0] + '-' + dateElementList[1];
    console.log(dateFormated);
    setEndDate(dateFormated);
  
    const timeElement = new Date(props.roomData.end).toString().split(' ')[4];
    // const hourElement = timeElement.split(':')[0];
    // const minuteElement = timeElement.split(':')[1];
    console.log(timeElement);
    setEndTime(timeElement);
  }


  return (
    <Fragment>
      {/* new-meetup-modal-content */}
      <div className={classes.control}>
        <div className={classes.title}>
          {/* Your Room Information */}
          {i18next.t("roomFormText11")}
        </div>
        <div>
          <div className={classes.label}>
            {/* Room Name (room's name) */}
            Room Name ({i18next.t("roomFormText12")})
          </div>
          <div>{props.roomData.roomId}</div>
        </div>
        <div>
          <div className={classes.label}>
            {/* Start Time (Start Date and Time) */}
            {i18next.t("roomFormText3")} (Start Date and Time)
          </div>
          <div>
            {/* {startDate}{', '}{startTime.split(':')[0]}{':'}{startTime.split(':')[1]}
            {' '}
            ({new Date(props.roomData.start).toLocaleString()}) */}
            {dateTimeDisplayOutput('', props.roomData.start)}
          </div>
        </div>
        <div>
          <div className={classes.label}>
            {/* End Time (End Date and Time) */}
            {i18next.t("roomFormText4")} (End Date and Time)
          </div>
          <div>
            {/* {endDate}{', '}{endTime.split(':')[0]}{':'}{endTime.split(':')[1]}
            {' '}
            ({new Date(props.roomData.end).toLocaleString()}) */}
            {dateTimeDisplayOutput('', props.roomData.end)}
          </div>
        </div>
        <div>
          <div className={classes.label}>
            {/* Maximum Participatns (Maximum number of participants) */}
            {i18next.t("roomFormText5")} (Maximum number of participants)
          </div>
          <div>{props.roomData.maxPeople}</div>
        </div>
        <div>
          <div className={classes.label}>
            {/* Email address (Email address) */}
            {i18next.t("roomFormText6")} (Email address)
          </div>
          <div>{props.roomData.email}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default GetMeetupContent;