// import { useTranslation } from "next-i18next";
import i18next from 'i18next';

import Loader from '../Loader/Loader';
import classes from './Modal.module.css'

function Modal(props) {
  // console.log('modal-props', props);

  // const { t } = useTranslation("common");

  function cancelHandler() {
    props.onCancel();

    if (props.setSelectAction) {
      props.setSelectAction('');
    }
  }

  function confirmHandler() {
    if (props.selectAction === 'DELETE') {
      console.log('DELETE clicked');
      props.onDelete(props.roomData);
    }
    else {
      props.onConfirm(props.roomData);
    }    
  }

  // let buttonName = 'Confirm';
  // let buttonName = 'Make Reservation';
  let buttonName = i18next.t("roomFormText7")
  if (props.selectAction === 'DELETE') {
    // buttonName = 'Delete'
    buttonName = i18next.t("generalText3")
  }
  if (props.selectAction === 'EDIT') {
    // buttonName = 'Edit'
    buttonName = i18next.t("generalText5")
  }

  return (
    <div className={classes.modal}>
      {/* <p>Are you sure?</p> */}
      <span>{props.children}</span>
      <button className={classes.modalCancelButton} 
        onClick={cancelHandler}
        disabled={props.isLoading}
      >
        {/* Cancel */}
        {i18next.t("generalText1")}
      </button>
      <button className={classes.modalConfirmButton} 
        onClick={confirmHandler}
        disabled={props.isLoading}
      >
        {props.isLoading ? 'Loading...' : buttonName}
      </button>
      <div className={classes.modalLoader}>{props.isLoading && <Loader />}</div>
      <div className={classes.resultMessage}>{props.createMessage}</div>
      <div className={classes.resultMessage}>{props.error}</div>
    </div>
  );
}

export default Modal;