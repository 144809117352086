import { Fragment, useEffect, useState } from "react";
import i18next from "i18next";

import Card from "../../components/ui/Card";
import Loader from "../../components/Loader/Loader";
import TrialInformationContent from "../../components/trial-information/TrialInformationContents";
import { useStore } from "../../hooks-store/store";
import { fetchPrices } from "../../utils/stripe";

import classes from "./TrialInformation.module.css";

function TrialInformation(props) {
  const [store, dispatch] = useStore();

  const [isLoading, setIsLoading] = useState(true);

  const prices = store.stripeData.prices;

  useEffect(() => {
    setIsLoading(true);

    fetchPrices()
      .then((res) => {
        // console.log('fetchPrice-res', res);
        setIsLoading(false);
        dispatch("SET_PRICES", res.prices);
      })
      .catch((err) => {
        // console.log('fetchPrice-err', err);
        setIsLoading(false);
      });
  }, []);


  if (isLoading) {
    return (
      <div className={classes.trialInfoLoader}>
        <Loader />
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <div className={classes.trialInfoCardContents}>
          <TrialInformationContent prices={prices}/>
        </div>
      </Card>
    </Fragment>
  );
}

export default TrialInformation;
