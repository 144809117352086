// import Loader from '../Loader/Loader';
import classes from './Modal.module.css'

function CreateRoomModal(props) {
  // console.log('modal-props', props);

  function cancelHandler() {
    props.onCancel();

    // if (props.setSelectAction) {
    //   props.setSelectAction('');
    // }
  }

  // function confirmHandler() {
  //   if (props.selectAction === 'DELETE') {
  //     console.log('DELETE clicked');
  //     props.onDelete(props.roomData);
  //   }
  //   else {
  //     props.onConfirm(props.roomData);
  //   }    
  // }

  // let buttonName = 'Confirm';
  // if (props.selectAction === 'DELETE') {
  //   buttonName = 'Delete'
  // }
  // if (props.selectAction === 'EDIT') {
  //   buttonName = 'Edit'
  // }

  return (
    <div className={classes.createRoomModal}>
      {/* <p>Are you sure?</p> */}
      <div className={classes.createRoomModalClose}>
        <span className={classes.createRoomModalCloseButton}
          onClick={cancelHandler}
        >
          X
        </span>
      </div>
      <span>{props.children}</span>
      {/* <button className={classes.modalCancelButton} 
        onClick={cancelHandler}
        disabled={props.isLoading}
      >
        Cancel
      </button>
      <button className={classes.modalConfirmButton} 
        onClick={confirmHandler}
        disabled={props.isLoading}
      >
        {props.isLoading ? 'Loading...' : buttonName}
      </button>
      <div className={classes.modalLoader}>{props.isLoading && <Loader />}</div>
      <div className={classes.resultMessage}>{props.createMessage}</div>
      <div className={classes.resultMessage}>{props.error}</div> */}
    </div>
  );
}

export default CreateRoomModal;