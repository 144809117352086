import { Fragment } from "react";
import i18next from "i18next";

import Loader from "../../Loader/Loader";
import { subscriptionBasicPriceId, subscriptionMiniPriceId } from "../../../App";

import classes from "./PricesDetail.module.css";

function PricesDetail(props) {
  // console.log('PricesDetail-props', props);
  const {
    price,
    isLoading,
  } = props;

  let pricesDetailBody;

  if (price.id === subscriptionMiniPriceId) {
    pricesDetailBody = (
      <div className={classes.priceDetailContainer}>
        {/* <div>price.id: {price.id}</div>
        <div>
          product.name: {price.product.name}
        </div> */}
        <div className={classes.priceDetailInfo}>
          {i18next.t("subText40", "Maximum participants per meeting")}: 6
        </div>
        <div className={classes.priceDetailInfo}>
          {i18next.t("subText41", "Maximum visible screen per user")}: 6
        </div>
        <div className={classes.priceDetailInfo}>
          {i18next.t("trialText4", "No limit for usage")}
        </div>
        <div className={classes.priceDetailInfo}>
          {i18next.t("subText43", "Text chat, Screen sharing, File sharing")}
        </div>
        {/* <div>
          video-quality-limit: medium quality
        </div> */}
      </div>
    );
  }


  if (price.id === subscriptionBasicPriceId) {
    pricesDetailBody = (
      <div className={classes.priceDetailContainer}>
        {/* <div>price.id: {price.id}</div>
        <div>
          product.name: {price.product.name}
        </div> */}
        <div className={classes.priceDetailInfo}>
          {i18next.t("subText40", "Maximum participants per meeting")}: 100
        </div>
        <div className={classes.priceDetailInfo}>
          {i18next.t("subText41", "Maximum visible screen per user")}: 6
        </div>
        <div className={classes.priceDetailInfo}>
          {i18next.t("trialText4", "No limit for usage")}
        </div>
        <div className={classes.priceDetailInfo}>
          {i18next.t("subText43", "Text chat, Screen sharing, File sharing")}
        </div>
        <div className={classes.priceDetailInfo}>
        {i18next.t("subText42", "Video quality become lower when more than 15 participants attend in one meeting.")}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {pricesDetailBody}
    </Fragment>
  );
}

export default PricesDetail;
