import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import './App.css';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import Backdrop from '../../../components/backdrop/Backdrop';
import CancelModalContent from '../../../components/subscription/cancel/CancelModalContent';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/Loader/Loader';
import ModalSimple from '../../../components/modal/ModalSimple';
import { useStore } from '../../../hooks-store/store';

import { BACKEND_URL, SUBSCRIPTION_BACKEND_URL } from '../../../App';

import classes from './Cancel.module.css';

const Cancel = (props) => {
  const priceData = props.location.state.priceData;

  const [state, dispatch] = useStore();

  const [cancelled, setCancelled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // const { subscription, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/cancel-subscription', {
    //   method: 'POST',
    //   headers: {
    //     Authorization: 'Bearer ' + localStorage.getItem('token'),
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     subscriptionId: props.location.state.subscription
    //   }),
    // })

    // if (error) {
    //   // console.log(error);
    //   setIsLoading(false);
    //   return;
    // }
    // if (!error) {
    //   // console.log(subscription);
    //   setIsLoading(false);
    //   setCancelled(true);
    // }

    const result = await fetch(SUBSCRIPTION_BACKEND_URL + '/subscription/cancel-subscription', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: props.location.state.subscription,
        customerId: localStorage.getItem('customerId'),
        priceId: priceData.id,
      }),
    })

    const resData = await result.json();

    if (!result.ok) {
      // console.log(error);
      setErrorMessage('Cancel Failed');
      setIsLoading(false);
      return;
    }
    else {
      // console.log(subscription);
      setIsLoading(false);
      setCancelled(true);
    }

    dispatch('SHOW_NOTIFICATION', {
      status: 'success',
      title: 'Unsubscribed',
      message: '',
    });

    setTimeout(() => {
      dispatch('CLEAR_NOTIFICATION')
    }, 1000*5);
  };

  if(cancelled) {
    return <Redirect to={`/subscription/account`} />
    // return <Redirect to={`/`} />
  }

  return (
    <div>
      {showCancelModal &&
        <div>
          <Backdrop />
          <ModalSimple>
            <CancelModalContent 
              setShowCancelModal={setShowCancelModal}
              handleClick={handleClick}
              errorMessage={errorMessage}
              isLoading={isLoading}
            />
          </ModalSimple>
        </div>
        }

      <Card>
        <div className={classes.subscriptionCardContents}>
          <h2>
            {/* Cancel */}
            {i18next.t('generalText1', 'Cancel')}
          </h2>
          <div className={classes.subscriptionCancelContents}>
            {/* Type: {priceData && priceData.product.name} */}
            {i18next.t('subText4', 'Type')}: {priceData && priceData.product.name}
          </div>
          <Link
            to={{
              pathname: "/subscription/account",
              // state: { 
              //   subscription: props.subscription.id, 
              //   data: props.subscription,
              //   priceData: priceData,
              //  },
            }}
          >
            <button className={classes.subscriptionCancelButton}
              disabled={isLoading}
            >
              {/* Go Back */}
              {i18next.t('subText12', 'Go Back')}
            </button>
          </Link>
          <button className={classes.subscriptionButton}
            onClick={() => {
              setShowCancelModal(!showCancelModal);
              setErrorMessage(''); 
            }} 
            disabled={isLoading}
          >
            {/* Cancel Subscription */}
            {i18next.t('subText10', 'Cancel Subscription')}
          </button>

          {isLoading && <div><Loader /></div>}
        </div>
      </Card>
    </div>
  )
}


export default withRouter(Cancel);
