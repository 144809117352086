import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_PRICES: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          prices: payload,
        }
      }
    },
    SET_SUBSCRIPTIONS: (state, payload) => {
      return {
        stripeData: {
          ...state.stripeData,
          subscriptions: payload,
        }
      }
    },
    // SET_CURRENT_SUBSCRIPTION: (state, payload) => {
    //   return {
    //     stripeData: {
    //       ...state.stripeData,
    //       currentSubscription: payload,
    //     }
    //   }
    // }
    // SET_HAVESUBSCRIPTION: (state, payload) => {
    //   return {
    //     stripeData: {
    //       ...state.stripeData,
    //       haveSubscription: payload,
    //     }
    //   }
    // },
    // SET_SUBSCRIPTIONINFO: (state, payload) => {
    //   return {
    //     stripeData: {
    //       ...state.stripeData,
    //       subscriptionInfo: {
    //         subscriptionId: payload.subscriptionId,
    //         status: payload.status,
    //         priceId: payload.priceId,
    //       }
    //     }
    //   }
    // },
    // ADD: (state, amount) => ({
    //     counter: state.counter + amount,
    //     clickNumber: state.clickNumber + 1,
    // }),
    // SHOW_NOTIFICATION: (state, payload) => {
    //   const notifyContent = {
    //     status: payload.status,
    //     title: payload.title,
    //     message: payload.message,
    //   };
    //   return { notification: notifyContent };
    // },
    // CLEAR_NOTIFICATION: (state, payload) => {
    //   const notifyContent = {
    //     status: null,
    //     title: null,
    //     message: null,
    //   };
    //   return { notification: notifyContent };
    // },
  };

  initStore(actions, {
    stripeData: {
      // customer: null,
      // haveSubscription: false,
      subscriptions: [],
      // activeSubscriptions: [],
      prices: [],
      // currentSubscription: undefined,
      // subscriptionInfo: {
      //   subscriptionId: null,
      //   status: null,
      //   priceId: null,
      //   //.....
      // }
    }
  });
};

export default configureStore;
