import React from 'react';

import classes from './Loader.module.css';

const loader = props => (
  <div className={props.loaderStyle ? props.loaderStyle : classes.loader}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default loader;
